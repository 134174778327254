div.checkerFlat {
  position: relative;
  width: 100%;
  height: 5.2%;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 6.3%;
  box-shadow: 0 0 5px 0px var(--black);
}

div.checkerFlatP2 {
  background: linear-gradient(90deg, #131313 0%, #666666 50.52%, #121211 100%);
}

div.checkerFlatP1 {
  background: linear-gradient(90deg, #7b8082 0%, #fbfafa 50.52%, #7b8082 100%);
}

@media screen and (max-width: 800px) {
  div.checkerFlat {
    box-shadow: -1px -1px 4px 1px rgba(0, 0, 0, 0.5) inset, 1px 1px 1px 1px rgba(255, 255, 255, 0) inset;
  }
}
