.pick-checker {
  width: 40px;
  height: 40px;
  opacity: 0.5;
  border: 5px solid transparent;
  border-radius: 50%;
  margin: 8px;

  &--picked {
    border-color: var(--green);
    opacity: 1;
  }
}
