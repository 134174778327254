.contacts {
  position: relative;
  display: flex;

  .page-name {
    font-family: 'Roboto Slab', serif;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 3px;
  }

  .button-back {
    width: 33px;
  }

  .contacts-card {
    width: 474px;
    max-height: 461px;
    border: none;
    border-radius: 10px;
    background-color: rgba(24, 74, 50, 0.7);
    align-items: center;
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-down(sm) {
      width: 63%;
      max-width: 356px;
      height: 100%;
      max-height: none;
      padding-left: 26px;
      padding-right: 26px;
    }

    @include media-breakpoint-down(xs) {
      margin: 0;
      width: 100%;
      max-width: 100%;
      margin-top: 80px;
    }

    .label {
      height: 36px;
      font-family: Roboto Slab;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      margin-top: 50px;
      color: var(--white);
      opacity: 0.5;
    }

    .content {
      font-family: Roboto Slab;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      color: var(--white);
      align-items: center;
      text-align: center;
    }

    .end {
      margin-bottom: 50px;
    }
  }
}
