@import "variables";
// @import "bootstrap";

.btn-app-primary {
  // @include button-variant($green, darken($green, 7.5%), darken($green, 10%), darken($green,0%), darken($green, 0%), darken($green,0%));

  height: 40px;
  background: var(--green);
  border-radius: 10px;
  padding: 0 34px;
  font-weight: bold;
  font-size: 14px;
  color: var(--white);
  white-space: nowrap;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: var(--white);
    background: darken($green, 5%);
  }

  &:active {
    background: darken($green, 10%);
  }
}

.btn-app-decline {
  height: 40px;
  width: 140px;
  background: linear-gradient(180deg, #D14E4E 0%, #7B0F0F 100%);
  border-radius: 30px;
  padding: 0 44px;
  font-weight: bold;
  border: none;
  font-size: 14px;
  color: var(--white);
  white-space: nowrap;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: var(--white);
    background: linear-gradient(180deg, darken(#D14E4E, 2%) 0%, darken(#7B0F0F, 2%) 100%);
  }

  &:active {
    background: linear-gradient(180deg, darken(#D14E4E, 4%) 0%, darken(#7B0F0F, 4%) 100%);
  }
}

.btn-app-lite-green {
  height: 95%;
  width: 40%;
  background: linear-gradient(180deg, #16E142 0%, #143703 100%);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.83);
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  color: var(--white);
  white-space: nowrap;
  border: none;
  font-size: 1vw;
  font-family: 'Roboto Slab', serif;

  &:focus {
    outline: none;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.83);
  }

  &:hover {
    color: var(--white);
    background: linear-gradient(180deg, darken(#16E142, 2%) 0%, darken(#143703, 2%) 100%);
  }

  &:active {
    background: linear-gradient(180deg, darken(#16E142, 4%) 0%, darken(#143703, 4%) 100%);
  }
}

.btn-app-red {
  height: 40px;
  background: linear-gradient(180deg, #D14E4E 0%, #7B0F0F 100%);
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  color: var(--white);
  white-space: nowrap;
  border: none;
  font-size: 1vw;
  font-family: 'Roboto Slab', serif;

  &:focus {
    outline: none;
  }

  &:hover {
    color: var(--white);
    background: linear-gradient(180deg, darken(#D14E4E, 2%) 0%, darken(#7B0F0F, 2%) 100%);
  }

  &:active {
    background: linear-gradient(180deg, darken(#D14E4E, 4%) 0%, darken(#7B0F0F, 4%) 100%);
  }
}

.btn-app-outline {
  height: 38px;
  width: 140px;
  border-radius: 30px;
  padding: 0 44px;
  font-weight: bold;
  font-size: 14px;
  color: var(--white);
  border: 1px solid var(--white);
  white-space: nowrap;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: var(--white);
    background: rgba(255, 255, 255, 0.205);
  }

  &:active {
    color: #21573E;
    background: var(--white);
  }
}

.btn-app-main {
  height: 40px;
  width: 140px;
  background: linear-gradient(180deg, #FFAD31 0%, #fd6638 100%);
  border-radius: 30px;
  padding: 0 44px;
  font-weight: bold;
  border: none;
  font-size: 14px;
  color: var(--white);
  white-space: nowrap;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: var(--white);
    background: linear-gradient(180deg, #fcb346 0%, #fd6f43 100%);
  }

  &:active {
    background: linear-gradient(180deg, #FF6231 0%, #FFAD31 100%);
  }
}

.btn-app-danger {
  border: none;
  background-color: #DD2F45;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background-color: #7C0303;
  }

  &:active {
    background-color: #7C0303;
  }

  &:disabled {
    background-color: #DD2F45;
  }
}

.btn-app-white {
  border: none;
  background-color: var(--white);

  .icon-svg {
    fill: $bush;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
  
  &:hover {
    border: none;
    background-color: $greenPea;

    .icon-svg {
      fill: var(--white);
    }
  }

  &:disabled {
    border: none;
    background: var(--white);
    opacity: 0.4;

    .icon-svg {
      fill: $bush;
    }
  }
}

.circle-button--checked .btn-app-white {
  background-color: var(--green);

  .circle-button__content__icon .icon-svg {
    fill: var(--white);
  }
}

.btn-app-blue {
  border: none;
  background: linear-gradient(180deg, #5b9cd8 -50%, #24227c 162.5%);

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background: linear-gradient(180deg, #24227c -50%, #5b9cd8 162.5%);
  }
}

.btn-app-facebook {
  border: none;
  background: #4269A7;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background: #1C3864;
  }
}

.btn-app-empty {
  border: none;
  background: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    outline: none;
    box-shadow: none;
  }
}
