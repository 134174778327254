#board {
  height: 100%;
}

#leftSide {
  position: relative;
  width: 37.4%;
  height: 98.6%;
  background-image: url(../../../assets/wood-inside.jpg);
  border-radius: 0.5vw;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 3% 2.2% 3% 3.4%;
}

#middleSide {
  position: relative;
  width: 5.8%;
  bottom: 0;
  height: 108.7%;
  margin: 0 -0.4%;
  background-repeat: no-repeat;
  background-size: contain;

  .dice_blue {
    position: absolute;
    left: -9%;
    z-index: 1;
    width: 110%;
  }

  .dice_blue:first-child {
    top: 4%;
  }

  .dice_blue:last-child {
    bottom: 4%;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 28%;
    width: 45%;
    height: 100%;
    display: block;
    background: linear-gradient(270deg, rgba(51, 20, 10, 0) 0%, rgba(35, 35, 35, 0.97) 48.44%, rgba(51, 20, 10, 0) 100%)
  }
}

.mobile {
  #leftSide {
    width: 40.4%;
    height: 102.6%;
    margin: 2% 1.2% 2% 1.9%;
  }

  #rightSide {
    width: 40.4%;
    height: 102.6%;
    margin: 2% 1.9% 2% 1.2%;
  }
  
}

.hinge {
  position: absolute;
  left: 0px;
  height: 12%;
  width: 100%;
  background-image:  url(../../../assets/hinge.png);
  background-repeat: no-repeat;
  background-size: contain;

  &__top {
    top: 15%;
  }

  &__down {
    bottom: 15%;
  }
}

#rightSide {
  position: relative;
  width: 37.4%;
  height: 98.6%;
  background-image: url(../../../assets/wood-inside.jpg);
  border-radius: 0.5vw;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 3% 3% 3% 2.2%;
}

.diceContent_blue {
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  border-radius: 41%;
  padding: 29%;
  background: linear-gradient(174.01deg, #5d9de6 -63.37%, #175a94 208.07%);
  position: absolute;
  font-family: 'Ultra', serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dice_blue {
  width: 100%;
  position: relative;
  margin: 2% 3%;
  align-items: center;
  border-radius: 25%;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.56);
  background: linear-gradient(to bottom, #5fa6e4 -11.4%, #1c517c 130.1%);
  cursor: pointer;

  &__value {
    font-size: 2vw;
    background: -webkit-linear-gradient(80.91deg, #FEFFF7 36.21%, #AAD8FF 72.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    user-select: none;
  }

  &--disabled {
    background: linear-gradient(to bottom, #a6b2bd -11.4%, #383a3c 130.1%);
    pointer-events: none;

    .dice_blue__value {
      background: -webkit-linear-gradient(80.91deg, #9dacbd 36.21%, #afb6bd 72.72%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      user-select: none;
    }

    .diceContent_blue {
      background: linear-gradient(to bottom, #99a5b1 -63.4%, #2a3e46 208.1%);
    }
  }

  &:before {
    content: "";
    padding-top: 100%;
    display: block;
  }
}

.max-cube {
  background: linear-gradient(to bottom, #ffe876 -11.4%, #ab6f17 130.1%);
  pointer-events: none;

  .dice_blue__value {
    background: -webkit-linear-gradient(80.91deg, #fffef7 36.21%, #ffefaa 72.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    user-select: none;
  }

  .diceContent_blue {
    background: linear-gradient(174.01deg, #fbeeae -63.37%, #b36f00 208.07%);
  }
}

.lanes-bg-up {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  background-image: url(../../../assets/lanes-up.png);
  padding: 2.4% 1.4% 0;
  border-radius: 0.5vw;
}

.lanes-bg-down {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  background-image: url(../../../assets/lanes-down.png);
  padding: 0 1.4% 2.4%;
  border-radius: 0.5vw;
}

.diceAreaOpponent {
  position: absolute;
  top: 45%;
  left: 0;
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waitingOpponent {
  font-size: 1.6vw;
  color: greenyellow;
}

.noMovesAvailable {
  top: 110%;
}

.wood-logo {
  position: absolute;
  top: 45%;
  left: 0;
  height: 10%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 44%;
  background-position: center;
  background-image: url(../../../assets/wood-logo.png);
}

.buttons-area {
  position: absolute;
  top: 39%;
  right: 0;
  height: 22%;
  width: 28%;
  z-index: 3;
  pointer-events: none;

  .content-button {
    pointer-events: all;
    height: 34%;
    margin: 3%;
    border-radius: 4vw;
    width: 100%;
    padding: 0;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.83);

    &__content {
      height: 60%;
      width: 60%;
      margin: 0 5%;
    }

    &__text {
      text-align: start;
      font-weight: normal;
      width: 100%;
      padding-left: 4%;
      font-size: 0.8vw;
      margin: 0;
    }

    .timer-provider {
      height: 100%;
      width: 100%;

      > div {
        height: 100%;
        width: 100%;
      }
    }

    .timer-bar {
      height: 100%;
      width: 100%;
    }
  }
}

.opponent-roll-info{
  z-index: 1;
}
.undo-button{
  @include media-breakpoint-down(lg) {
    .content-button__text {
      font-size: 12px;
    }
  }
  @include media-breakpoint-down(md) {
    .content-button__text {
      font-size: 8px;
    }
  }
}

.done-button{
  @include media-breakpoint-down(lg) {
    .content-button__text {
      font-size: 12px;
    }
  }
  @include media-breakpoint-down(md) {
    .content-button__text {
      font-size: 8px;
    }
  }
}

.mobile {
  .dice_blue__value {
    font-size: 3.4vw;
  }

  .outSide__middle {
    margin: -10%;

    .dice_blue {
      margin: 0;
    }
  }

  .diceArea {
    height: 130%;
  }

  .roll-button {
    font-size: 2.5vw;
    padding: 0;
    width: 50%;
  }

}
