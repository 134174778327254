.app-modal {
  font-family: 'Roboto Slab', serif;
  text-align: center;
  overflow: hidden;

  .modal-content {
    background-color: #{$lightGreenPea};
    border-radius: 10px;
  }

  .modal-title {
    width: 100%;
    margin: 10px 32px 0;
    font-weight: bold;
    font-size: 24px;
  }

  .modal-header {
    border: none;
    padding: 18px 10px 0 10px;

    .close {
      display: block;
      position: absolute;
      right: 16px;
      padding: 0;
      margin: 0;
    }
  }

  .modal-body p {
    font-size: 18px;
    line-height: 24px;
    margin: 0;
  }

  .modal-footer {
    button {
      margin: 0 7px;
    }

    border: none;
    justify-content: center;
    padding-bottom: 24px;
  }
}

.modal-open .modal {
  overflow: hidden;
}

// Game Info Modal

.modal-backdrop.game-info-backdrop {
  background: transparent;
}

.game-info .modal-content{
  background-color: rgba(0, 0, 0, 0.62);
}

.game-info-dialog {
  .modal-title {
    text-shadow: black 0 0 12px;
  }
}

.modal-body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.modal-body::-webkit-scrollbar {
  display: none;
}

.modal-body{
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}