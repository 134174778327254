input.custom {
  color: var(--white);
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px #{$lightGreenPea} inset;
  -webkit-text-fill-color: var(--white);
  border: none;
  caret-color:var(--white);
}

input.custom:-webkit-autofill:hover,
input.custom:-webkit-autofill:focus,
input.custom:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #{$lightGreenPea} inset;
  -webkit-text-fill-color: var(--white);
}

textarea:focus, input:focus{
  outline: none;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
