:root {
  --checkerC1: rgb(211, 211, 211);
  --checkerC1Detail: rgb(88, 88, 88);
  --checkerC1Label: rgb(59, 59, 59);
  --checkerC2: rgb(53, 53, 53);
  --checkerC2Detail: rgb(161, 161, 161);
  --checkerC2Label: rgb(204, 204, 204);
  --canMove: rgba(72, 255, 0, 0.575);
}

div.checker {
  position: relative;
  width: 78%;
  margin: 0px auto;
  overflow: hidden;
  border-radius: 50%;
  pointer-events: none;
}

div.checkerP1 {
  background: radial-gradient(circle, var(--checkerC1) 0%, var(--checkerC1) 40%, var(--checkerC1Detail) 45%, var(--checkerC1) 50%, var(--checkerC1) 55%, var(--checkerC1Detail) 60%, var(--checkerC1) 65%, var(--checkerC1) 100%);
  box-shadow: -3px -3px 5px 5px rgba(0, 0, 0, 0.5) inset, 2px 2px 5px 3px rgba(255, 255, 255, 0.3) inset;
}

div.checkerP2 {
  background: radial-gradient(circle, var(--checkerC2) 0%, var(--checkerC2) 40%, var(--checkerC2Detail) 45%, var(--checkerC2) 50%, var(--checkerC2) 55%, var(--checkerC2Detail) 60%, var(--checkerC2) 65%, var(--checkerC2) 100%);
  box-shadow: -3px -3px 5px 5px rgba(0, 0, 0, 0.5) inset, 2px 2px 5px 5px rgba(255, 255, 255, 0.0) inset;
}

div.checker p {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  font-size: 1.8vw;
  text-align: center;
  font-weight: bold;
}

div.checkerP1 p {
  color: var(--checkerC1Label);
}

div.checkerP2 p {
  color: var(--checkerC2Label);
}

div.canMove {
  animation-name: canMove;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.checker-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88%;
  border-radius: 50%;

  &--dragging {
    opacity: 0;
  }
}

.svg-checker {
  user-select: none;
  margin: 1%;
  padding: 5%;
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.5));
  width: 100%;
  pointer-events: none;
  user-select: none;
}

.checker-num {
  position: absolute;
  user-select: none;
}

@keyframes canMove {
  0% {
    box-shadow: 0 0 15px 50px var(--canMove) inset;
  }

  50% {
    box-shadow: 2px 2px 5px 3px rgba(255, 255, 255, 0.0) inset;
  }

  100% {
    box-shadow: 0 0 15px 50px var(--canMove) inset;
  }
}

@media screen and (max-width: 800px) {
  @keyframes canMove {
    0% {
      box-shadow: 0 0 5px 20px var(--canMove) inset;
    }

    50% {
      box-shadow: 2px 2px 5px 3px rgba(255, 255, 255, 0.0) inset;
    }

    100% {
      box-shadow: 0 0 5px 20px var(--canMove) inset;
    }
  }
}
