.userInfo {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
  text-align: center;
  background-color: #{$greenPea};
  border-radius: 10px;

  .timer-provider {
    position: absolute;
    height: 88px;
    width: 88px;
    top: -7px;
    left: -7px;
  }

  .user-img {
    position:relative;
    width: 83px;
    height: 83px;
    margin-top: 20px;
    
    .checker-cont {
      position: absolute;
      width: 38%;
      margin: 0;
      left: 0;
      bottom: 0;
    }

    .checker {
      padding: 0;
    }

    .user-rating{
      color: #{$algaeGreen};
      padding: 2px;
      position: absolute;
      left: 60%;
      bottom: 0;
      background: #{$bush};
      border: 1px solid #425F51;
      border-radius: 6px;
      font-size: 13px;
      max-width: 60px;
      min-width: 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-top-left-radius: 0;
    }
  }
}

.userAvatar {
  position: relative;
  width: 80px;
  height: 80px;
  border: 3px solid var(--white);
  box-sizing: border-box;
  background: radial-gradient(50% 50% at 50% 50%, #{$seaGreen} 0%, #{$bush} 100%);

  img {
    width: 74px;
    height: 74px;
    vertical-align: unset;
  }
}

.user-name {
  margin-top: 15px;
  font-size: 14px;
  line-height: 18px;
  height: 18px;
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--white);
}

.userScore {
  margin-top: 5px;
  font-size: 30px;
  line-height: 40px;
  color: #{$sunshade};
}

@media (max-width: 570px) {
  .userInfo {
    background: none;
    border: none;

    .timer-provider {
      position: absolute;
      height: 46px;
      width: 46px;
      top: -5px;
      left: -5px;
    }

    .user-img {
      width: 41px;
      height: 41px;
      margin-top: 0;

      .user-rating {
        left: 44%;
        font-size: 9px;
        min-width: 28px;
        max-width: 38px;
        padding: 0;
      }
    }
  }

  .userAvatar {
    width: 38px;
    height: 38px;
    border: 1px solid var(--white);

    img {
      width: 36px;
      height: 36px;
    }
  }

  .user-name {
    font-size: 12px;
    line-height: 16px;
    width: 63px;
    height: 32px;
    margin-top: 10px;
    -webkit-line-clamp: 2;
  }

  .userScore {
    font-size: 24px;
    line-height: 32px;
    margin-top: 9px;
  }
}

@media (min-width: 571px) and (max-width: 1024px) {
  .userInfo {
    background: none;
    border: none;

    .timer-provider {
      position: absolute;
      height: 52px;
      width: 52px;
      top: -5px;
      left: -5px;
    }

    .user-img {
      width: 51px;
      height: 51px;
      margin-top: 0;

      .user-rating {
        left: 50%;
        font-size: 9px;
        min-width: 28px;
        max-width: 38px;
      }
    }
  }

  .userAvatar {
    width: 48px;
    height: 48px;

    img {
      width: 42px;
      height: 42px;
    }
  }

  .user-name {
    font-size: 12px;
    line-height: 16px;
    width: 63px;
    height: 32px;
    margin-top: 10px;
    -webkit-line-clamp: 2;
  }

  .userScore {
    font-size: 24px;
    line-height: 32px;
    margin-top: 9px;
  }
}
