.user-profile {
  max-width: 1114px;
  margin: 0 auto;
  position: relative;
  display: flex;

  .page-name {
    font-family: 'Roboto Slab', serif;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 3px;
  }

  .button-back {
    width: 33px;
  }

  .info-card {
    width: 474px;
    max-height: 461px;
    border: none;
    border-radius: 10px;
    background-color: rgba(24, 74, 50, 0.7);
    padding-left: 77px;
    padding-right: 77px;
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-down(sm) {
      width: 63%;
      max-width: 356px;
      max-height: none;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      padding-left: 26px;
      padding-right: 26px;
    }

    @include media-breakpoint-down(xs) {
      margin: 0;
      width: 100%;
      max-width: 100%;
      margin-top: 80px;
      padding-left: 10px;
      padding-right: 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .user-avatar {
      margin-right: auto;
      margin-left: auto;
      margin-top: 50px;
      width: 105px;
      height: 105px;
      border: 1px solid var(--white);
      box-sizing: border-box;
      background: radial-gradient(50% 50% at 50% 50%, #{$seaGreen} 0%, #{$bush} 100%);

      img {
        width: 103px;
        height: 103px;
      }

      @include media-breakpoint-down(sm) {
        margin-top: 15px;
        width: 60px;
        height: 60px;
        img {
          width: 58px;
          height: 58px;
        }
      }
    }

    .upload-button {
      height: 24px;
      width: 104px;
      margin-top: 26px;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid var(--white);
      border-radius: 29px;
      padding: 0;

      .text {
        font-family: Roboto;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: var(--white);
        margin: 4px 9px 4px 10px;
      }

      @include media-breakpoint-down(sm) {
        margin-top: 10px;

        .text {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }

    .profile-buttons {
      margin: 0;
      margin-top: 50px;
      margin-bottom: 50px;

      @include media-breakpoint-down(sm) {
        margin-top: 30px;
      }

      .btn-action {
        @include media-breakpoint-down(xs) {
          width: 100px;
        }
      }
    }

    .form {
      position: relative;
      margin-top: 54px;
  
      @include media-breakpoint-down(sm) {
        margin-top: 10px;
      }

      label {
        font-family: Roboto Slab;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: var(--white);
        opacity: 0.5;
      }

      .input-group {
        border-bottom: 1px solid var(--white);
        align-items: center;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
