.circle-button {
  width: 50px;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  border: none;

  &__content {
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    padding: 0;
    border: none;
    border-radius: 20px;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin: auto;
    }
  }

  &__text {
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 16px;
    width: 50px;
    margin-top: 15px;
    &__disabled {
      opacity: 0.4;
    }
  }
}

.content-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
}

// @include media-breakpoint-down(sm) {
//   .circle-button {
//     width: 25px;
    
//     &__content {
//       height: 25px;
//       width: 25px;
//       border-radius: 12.5px;

//       &__icon {
//         width: 15px;
//         height: 15px;
//       }
//     }

//     &__text {
//       display: none;
//       margin-top: 0;
//     }
//   }
// }

.mobile {
  .circle-button {
    margin: 0;
    width: 42px;
    
    &__content {
      height: 32px;
      width: 32px;
      border-radius: 50%;

      &__icon {
        width: 20px;
        height: 20px;
      }
    }

    &__text {
      display: none;
      margin-top: 0;
    }
  }
}
