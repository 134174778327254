.login-auth {
  &__form {
    position: relative;
    margin-bottom: 5%;

    @include media-breakpoint-up(md) {
      @media (min-height: 650px) {

        margin-bottom: 10%;
      }
    }

    label {
      font-family: 'Roboto Slab', serif;
    }

    .input-group {
      border-bottom: 1px solid var(--white);
      align-items: center;

      &--error {
        border-color: $error;

        .form-control {
          color: $error;
        }
      }
    }

    .forgot {
      text-align: right;

      &__link {
        color: $sunshade;
      }
    }

    .back {
      text-align: left;

      &__link {
        color: $sunshade;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;

    @include media-breakpoint-up(md) {
      @media (min-height: 650px) {

        margin-bottom: 10%;
      }
    }

    .register-button,
    .login-button {
      height: 40px;
      width: 210px;
      margin-bottom: 5%;
      font-family: 'Roboto Slab', serif;

      @include media-breakpoint-up(md) {
        @media (min-height: 650px) {
          margin-bottom: 10%;
        }
      }
    }

    .facebook-button {
      height: 40px;
      width: 210px;
      margin-top: 5%;
      font-family: 'Roboto Slab', serif;

      @include media-breakpoint-up(md) {
        @media (min-height: 650px) {
          margin-top: 10%;
        }
      }

      .content-button__content {
        @include media-breakpoint-down(md) {
          background: none;
        }
      }
    }
  }

  &__signup {
    text-align: center;

    .sign-up-link {
      color: $sunshade;
    }
  }
}

.loader {
  display: none;
}

.show-loader {
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
