html, body {
  height: 100vh;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.login {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;

  &__container {
    max-height: 800px;
    background-color: #{$lightGreenPea};
    margin: 0 auto;
    padding: 15px;
    max-width: 380px;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      padding: 15px 50px;
      @media (min-height: 650px) {
        padding: 50px;
      }
    }
  }

  &__logo {
    margin: 0 auto 5%;

    @include media-breakpoint-up(md) {
      @media (min-height: 650px) {
        margin: 0 auto 10%;
      }
    }

    &-img {
      position: relative;
      display: flex;
      justify-content: center;

      .white-dice, .black-dice {
        width: 32px;
        height: 32px;
        @include media-breakpoint-up(md) {
          width: 72px;
          height: 72px;
          @media (min-height: 650px) {
            width: auto;
            height: auto
          }
        }
      }

      .black-dice {
        position: relative;
        top: 7px;
        left: -7px;
        @include media-breakpoint-up(md) {
          top: 30px;
          left: -30px;
        }
      }
    }

    &-name {
      margin-top: 5%;
      display: flex;
      justify-content: center;
    }

    .logo-name {
      width: 161px;
      height: 33px;
      @include media-breakpoint-up(md) {
        width: auto;
        height: auto
      }
    }
  }

  &--loader {
    height: auto;
  }
}

@for $i from 1 through 10 {
  .bg-chip-black#{$i} {
    position: absolute;
    width: random(100)+px;
    left: percentage((10*$i+random(10))/100);
    top: percentage(random(100)/100);
    filter: blur(random(3)+px);
  }
  .bg-chip-white#{$i} {
    position: absolute;
    width: random(100)+px;
    left: percentage((10*$i+random(10))/100);
    top: percentage(random(100)/100);
    filter: blur(random(3)+px);
  }
}
