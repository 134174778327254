:root {
  --color1: rgba(221, 201, 181, 0.79);
  --color2: rgba(153, 34, 34, 0.72);
  --receiveColor: rgba(44, 129, 27, 0.758);
  --canDropColor: rgba(27, 80, 129, 0.758);
  --activeDropColor: rgba(166, 255, 0, 0.596);
}

.triangle {
  height: 100%;
  padding: 0;
}

.trianglePart {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
}


.triangleLeftUpReceivable {
  border-top-left-radius: 65px;
  left: 0%;
  margin-top: 1%;

  background: linear-gradient(to left bottom, var(--receiveColor) 0%, var(--receiveColor) 49%, transparent 51%);
}

.triangleRightUpReceivable {
  border-top-right-radius: 65px;
  left: 50%;
  margin-top: 2%;

  background: linear-gradient(to right bottom, var(--receiveColor) 0%, var(--receiveColor) 49%, transparent 51%);
}

.triangleLeftDownReceivable {
  border-bottom-left-radius: 65px;
  left: 0%;
  margin-bottom: 5%;

  background: linear-gradient(to left top, var(--receiveColor) 0%, var(--receiveColor) 49%, transparent 51%);
}

.triangleRightDownReceivable {
  border-bottom-right-radius: 65px;
  left: 50%;
  margin-bottom: 5%;

  background: linear-gradient(to right top, var(--receiveColor) 0%, var(--receiveColor) 49%, transparent 51%);
}

.containerClickable {
  cursor: pointer;
}

.triangleLeftUp,
.triangleLeftUpC2 {
  border-top-left-radius: 65px;
  left: 0%;
  margin-top: 1%;
}

.triangleRightUp,
.triangleRightUpC2 {
  border-top-right-radius: 65px;
  left: 50%;
  margin-top: 2%;
}

.triangleLeftDown,
.triangleLeftDownC2 {
  border-bottom-left-radius: 65px;
  left: 0%;
  margin-bottom: 5%;
}

.triangleRightDown,
.triangleRightDownC2 {
  border-bottom-right-radius: 65px;
  left: 50%;
  margin-bottom: 5%;
}

.active-drop {
  .triangleLeftUpReceivable {
    background: linear-gradient(to left bottom, var(--activeDropColor) 0%, var(--activeDropColor) 49%, transparent 51%);
  }
  .triangleRightUpReceivable {
    background: linear-gradient(to right bottom, var(--activeDropColor) 0%, var(--activeDropColor) 49%, transparent 51%);
  }
  .triangleLeftDownReceivable {
    background: linear-gradient(to left top, var(--activeDropColor) 0%, var(--activeDropColor) 49%, transparent 51%);
  } 
  .triangleRightDownReceivable {
    background: linear-gradient(to right top, var(--activeDropColor) 0%, var(--activeDropColor) 49%, transparent 51%);
  }
}

.active-drop {
  .triangleLeftUp,
  .triangleLeftUpC2 {
    background: linear-gradient(to left bottom, var(--activeDropColor) 0%, var(--activeDropColor) 49%, transparent 51%);
  }
  
  .triangleRightUp,
  .triangleRightUpC2 {
    background: linear-gradient(to right bottom, var(--activeDropColor) 0%, var(--activeDropColor) 49%, transparent 51%);
  }

  .triangleLeftDown,
  .triangleLeftDownC2 {
    background: linear-gradient(to left top, var(--activeDropColor) 0%, var(--activeDropColor) 49%, transparent 51%);
  }

  .triangleRightDown,
  .triangleRightDownC2 {
    background: linear-gradient(to right top, var(--activeDropColor) 0%, var(--activeDropColor) 49%, transparent 51%);
  }
}

// .can-drop .triangleLeftUpReceivable {
//   background: linear-gradient(to left bottom, var(--receiveColor) 0%, var(--receiveColor) 49%, transparent 51%);
// }

// .can-drop .triangleRightUpReceivable {
//   background: linear-gradient(to right bottom, var(--receiveColor) 0%, var(--receiveColor) 49%, transparent 51%);
// }

// .can-drop .triangleLeftDownReceivable {
//   background: linear-gradient(to left top, var(--receiveColor) 0%, var(--receiveColor) 49%, transparent 51%);
// }

// .can-drop .triangleRightDownReceivable {
//   background: linear-gradient(to right top, var(--receiveColor) 0%, var(--receiveColor) 49%, transparent 51%);
// }






// .triangleLeftUp {
//   left: 0;
//   background: linear-gradient(to left bottom, var(--color2) 0%, var(--color2) 49%, transparent 51%);
// }

// .triangleRightUp {
//   left: 50%;
//   background: linear-gradient(to right bottom, var(--color2) 0%, var(--color2) 49%, transparent 51%);
// }

// .triangleLeftUpC2 {
//   left: 0;
//   background: linear-gradient(to left bottom, var(--color1) 0%, var(--color1) 49%, transparent 51%);
// }

// .triangleRightUpC2 {
//   left: 50%;
//   background: linear-gradient(to right bottom, var(--color1) 0%, var(--color1) 49%, transparent 51%);
// }

// .triangleLeftDownC2 {
//   left: 0;
//   background: linear-gradient(to left top, var(--color1) 0%, var(--color1) 49%, transparent 51%);
// }

// .triangleRightDownC2 {
//   left: 50%;
//   background: linear-gradient(to right top, var(--color1) 0%, var(--color1) 49%, transparent 51%);
// }


// .triangleLeftDown {
//   left: 0;
//   background: linear-gradient(to left top, var(--color2) 0%, var(--color2) 49%, transparent 51%);
// }

// .triangleRightDown {
//   left: 50%;
//   background: linear-gradient(to right top, var(--color2) 0%, var(--color2) 49%, transparent 51%);
// }
