:root {
  --rollButtonColor: rgb(248, 248, 248);
}

.rollButton {
  position: relative;
  width: 25%;
  margin: 2% 3%;
}

.rollButton::before {
  content: "";
  padding-top: 50%;
  display: block;
}

.rollButtonContent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: var(--rollButtonColor);
  box-shadow: 1px 1px 3px 3px rgba(255, 255, 255, 0.5) inset, -1px -1px 3px 3px rgba(0, 0, 0, 0.5) inset, 5px 5px 3px 6px rgba(0, 0, 0, 0.5);
}

.clickable {
  cursor: pointer;
}

.rollButtonContent p {
  margin-top: 10%;
  text-align: center;
  font-weight: bold;
  font-size: 1.5vw;
}

@media screen and (max-width: 800px) {
  .rollButtonContent {
    box-shadow: 1px 1px 4px 2px rgba(255, 255, 255, 0.5) inset, -1px -1px 4px 2px rgba(0, 0, 0, 0.5) inset, 5px 5px 3px 6px rgba(0, 0, 0, 0.5);
  }
}
