.last-page-block {
  width: 22%;

  @include media-breakpoint-down(xs) {
    width: inherit;
  }
}

.page-name {
  max-width: 240px;
  font-family: 'Roboto Slab', serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  vertical-align:top;
}

.button-back {
  width: 33px;
}

.privacy {
  width: 52%;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-down(xs) {
    margin: 0;
    width: 100%;
    max-width: 100%;
    margin-top: 120px;
  }

  .title {
    font-family: Roboto Slab;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: $sunshade;
  }

  .paragraph {
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
    margin-top: 20px;

    .item-title {
      padding-top: 31px;
      font-weight: bold;
    }

    .item-content {
      padding-top: 21px;
    }
  }
}
