.alerts {
  position: absolute;
  max-width: 300px;
  width: 100%;
  bottom: 1.5rem;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  @include media-breakpoint-up(sm) {
    left: initial;
    transform: initial;
    right: 1.5rem;
  }
}
