$green: #298058;
$greenPea: #174731;
$lightGreenPea: #1D593D;
$seaGreen: #2D9162;
$algaeGreen: #94E4C0;
$sunshade: #FFAD31;
$black: #000000;
$gray: #828282;
$codGray: #131312;
$white: #FFFFFF;
$rollingStone: #7B8082;
$havelockBlue: #5B9CD8;
$bush: #133A28;
$error: #dc3545;
$green05: rgba(#298058, 0.5);

