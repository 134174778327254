:root {
  --outSidereceiveColor: rgba(57, 163, 36, 0.508);
  --activeDropColor: rgba(166, 255, 0, 0.596);
}

.outSide {
  position: relative;
  width: 7.2%;
  height: 98.6%;

  &__up,
  &__down {
    position: relative;
    height: 42%;
    background-image: url("../../../assets/wood-inside.jpg");
    border-radius: 0.5vw;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 9% 7%;
  }

  &__up {
    order: 0;
  }

  &__down {
    order: 2;
  }

  &__middle {
    height: 18%;
    display: flex;
    align-items: center;
    order: 1;
    margin: -6%;
  }

  &__timer {
    width: 100%;
    height: 42%;
    background: #FFAD31;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0px 0px 9.05192px #6D2700;
    border-radius: 4.52596px;
    font-weight: bold;
    font-size: 2vw;
    font-family: 'Roboto Slab', serif;
  }

  &--receivable {
    background-color: var(--outSidereceiveColor);
    cursor: pointer;
  }

  &--left {
    order: -1;
    margin: 3% 0 3% 3%;
  }

  &--right {
    order: 10;
    margin: 3% 3% 3% 0;
  }

  &--upside-down {
    .outSide__up {
      order: 2;
    }

    .outSide__down {
      order: 0;
    }
  }
}

.mobile .outSide {
  height: 102.7%;

  &--left {
    margin: 2% 0 1% 1.8%;
  }
  &--right {
    margin: 2% 1.8% 2% 0
  }
}

.outsideContainer {
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-flow: column-reverse;

  &.active-drop {
    background-color: var(--activeDropColor);
  }
}

.undoButton {
  position: absolute;
  top: 45%;
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.undoButton button {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 1.22vw;
  font-weight: bold;
}

.undoEnabled {
  cursor: not-allowed;
}
