.diceArea {

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  pointer-events: none;
  height: 100%;

  &__wrapper {
    position: absolute;
    top: 45%;
    left: 0;
    z-index: 1;
    height: 10%;
    width: 100%;
  }

  > * {
    pointer-events: all;
  }
}
.information-message {
  background-color: #CB4A4A;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
  font-size: 10px;
  margin: 5px auto;
  text-align: center;
  @include media-breakpoint-up(md) {
    margin: 10px auto;
    font-size: 14px;
    min-width: 180px;
    padding: 0.5rem 2rem;
  }
}
.roll-button.btn{
  @include media-breakpoint-down(lg) {
    .content-button__text {
      font-size: 12px;
    }
  }
  @include media-breakpoint-down(md) {
    .content-button__text {
      font-size: 8px;
    }
  }
}
