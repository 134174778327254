.footer {
  height: 40px;
  width: 100%;
  background: #0d2b1d;
  flex-shrink: 0;

  @include media-breakpoint-down(xs) {
    height: 20px;
  }

  .footer-link {
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: var(--white);
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 50px;
  }
}
