@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Roboto+Slab:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ultra&display=swap');
@import "buttons";
@import "table";
@import "input";
@import "components";
@import "variables";

:root {
  --white: #{$white};
  --green: #{$green};
  --black: #{$black};
  --gold: #FFAD31;
}

body {
  color: var(--white);
}
