.userGames {
  width: 82%;
  height: 20%;
  margin-top: 15px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.24);
  border-radius: 7px;
  font-family: 'Roboto', serif;
  align-items: center;
  text-align: center;
}

.userGamesHeader {
  font-size: 11px;
  line-height: 14px;
  margin-top: 8px;
  color: #{$algaeGreen};
  font-weight: normal;

  &--short {
    display: none;
  }
}

.userGamesValue {
  font-size: 13px;
  line-height: 18px;
  margin-top: 2px;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--white);
  font-weight: bold;
  font-family: 'Roboto Slab', serif;

}

.endPadding {
  padding-bottom: 0;
}

.tableGames {
  display: flex;
  flex-direction: row;
  padding: 0;
}

.tableGames li {
  list-style-type: none;
  width: 100%;
}

.borderGames {
  border-right: 1px solid #425F51;
}

@include media-breakpoint-down(sm) {
  .userGamesHeader {
    display: none;

    &--short {
      display: block;
    }
  }
}

.mobile {
  .userGames {
    width: calc(100% - 14px);
    margin: 6px 0 10px;
  }
}
