.sidebar-menu {
  align-items: center;
  text-align: center;

  &__rating{
    color: #{$algaeGreen};
  }

  &__name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .userAvatar-menu {
    margin-right: auto;
    margin-left: auto;
    margin-top: 18px;
    width: 50px;
    height: 50px;
    border: 2px solid var(--white);
    box-sizing: border-box;
    background: radial-gradient(50% 50% at 50% 50%, #{$seaGreen} 0%, #{$bush} 100%);

    img {
      width: 46px;
      height: 46px;
    }
  }

  .close {
    color: var(--white);
    display: block;
    position: absolute;
    padding: 0;
    margin-top: -5px;
    right: 9px;
  }

  .user-name-menu {
    width: 90%;
    max-width: 90%;
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin: 10px auto auto;
    display: flex;
    justify-content: center;
  }

  .userGames {
    width: 90%;
    height: 100%;
    margin: 10px auto auto;
    max-width: none;
  }

  .menu {
      margin-top: 11px;
      margin-bottom: 11px;

      .menu-item{
          width: 90%;
          padding: 15px 0px 15px 0px;

          .text{
              color: var(--white);
              font-family: Roboto;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;
          }
      }

      .menu-item-first {
          border-bottom: 1px solid #1A5137;
      }
  }

  .menu-button-disableAd {
    width: 90%;
    font-family: Roboto Slab;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }
}
