.lobby {
  &__sort-button {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--white);
    font-weight: 700;
    &:focus{
      outline: none;
    }
  }
}

.content-button {
  &__content {
    background-color: transparent;
  }
}

.table {
  &__container {
    padding: 0 1.5rem;
  }

  &-header {
    max-width: 1114px;
    margin: 0 auto;
    font-family: 'Roboto Slab', serif;
    font-weight: bold;

    @include media-breakpoint-up(md) {
      margin-top: 2rem;
    }

    &__title {
      font-size: 14px;
      line-height: 14px;
      min-width: 120px;
      margin-right: 12px;

      @include media-breakpoint-up(md) {
        line-height: 24px;
        font-size: 24px;
        max-width: 200px;
      }

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    &__controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      flex: 1 1 auto;

      .controls__wrapper {
        display: flex;
      }
    }

    .row {
      align-items: center;
      padding: 0 1.5rem;
      flex-wrap: nowrap;
    }

    .content-button {
      margin-right: 6px;
    }

    .btn-app-main {

      @include media-breakpoint-down(sm) {
        height: 30px;
        width: 100px;
        font-size: 12px;
      }
    }
  }
}

.lobby {
  border-radius: 10px;
  overflow: hidden;
  color: var(--white);
  margin: 1rem auto;
  @include media-breakpoint-up(md) {
    width: 100%;
    max-width: 1114px;
  }

  &-btn {
    background-color: transparent;
    border-radius: 30px;
    padding: 0 22px;
    border: 1px solid var(--white);

    font-family: 'Roboto', serif;
    font-size: 14px;
    line-height: 20px;
    width: 74px;
    height: 24px;
  }

  &__ranking {
    display: none;
    @include media-breakpoint-up(md) {
      display: table-cell;
    }
  }

  &__sort-button {
    span {
      margin-right: 10px;
    }
  }

  .sort-down {
    transform: rotate(180deg);
  }

  thead {
    background-color: $green;
    font-family: 'Roboto Slab', serif;
  }

  td, th {
    border: none;
    text-align: center;
    vertical-align: unset;
  }

  th:first-child {
    text-align: left;
  }

  td:first-child {
    display: flex;
    align-items: center;
  }

  .dark {
    background-color: $bush;
  }

  .light {
    background-color: #{$lightGreenPea};
  }

  tr {
    height: 50px;
  }

  .avatar-wrapper {
    height: 30px;

    .rounded-circle {
      height: 100%;
    }
  }
}

.pagination {
  justify-content: center;
  margin-top: 3rem;
}

.page-item {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  .page-link {
    font-family: 'Roboto Slab', serif;
    border-radius: 5px;
    background-color: #{$lightGreenPea};
    color: var(--white);
    border: none;
    margin-right: 10px;
    padding: 0.75rem 1rem;
  }

  &.disabled, &.active, &.more {
    opacity: 0.5;

    .page-link {
      font-family: 'Roboto Slab', serif;
      border: none;
      background-color: #{$lightGreenPea};
      color: var(--white);
    }
  }

}

.single-pl-btn {
  width: 136px;
}

.update-btn {
  height: 40px;
  width: 136px;
  margin-right: 6px;
}

@include media-breakpoint-down(sm) {
  .update-btn {
    height: 30px;
    width: 100px;
    font-size: 10px;
    line-height: 13px;
    white-space: nowrap;
  }
}
