.header {
  margin-top: 24px;
  height: 90px;

  &__user-stats {
    max-width: 50%;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
  }

  .header-name {
    font-size: 14px;
    line-height: 18px;
    font-family: 'Roboto Slab', serif;
    color: var(--white);
    margin: 6px 15px 0px 0px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .header-userAvatar {
    width: 30px;
    height: 30px;
    margin-top: 0;
    border: 1px solid var(--white);
    box-sizing: border-box;
    background: radial-gradient(50% 50% at 50% 50%, #{$seaGreen} 0%, #{$bush} 100%);
    float: right;

    img {
      width: 28px;
      height: 28px;
    }
  }

  .button-disableAd {
    max-width: 140px;
    padding: 0 2rem;
    margin-right: 2rem;
    font-family: Roboto Slab;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }

  .userGames {
    height: 100%;
    max-width: 170px;
    max-height: 50px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .banner {
    margin: 0 20px;
  }

  .profile-button {
    border: none;
    padding: 0;
  }

  .dropdown {
    min-width: 180px;
    max-width: 30%;
  }

  .dropdown-menu {
    color: var(--white);
    max-width: 100%;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 0;
    margin-top: 14px !important;

    .item {
      color: var(--white);

      &:focus {
        background: #e6e6e6;
      }

      &:hover {
        background: #e6e6e6;
      }
    }

    .item-first {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .item-second {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .text {
      font-size: 14px;
      font-family: 'Roboto';
      color: #{$bush};
      height: 18px;
      margin: 15px 15px 15px;
    }
  }

  .dropdown-menu::before {
    content: '';
    position: absolute;
    right: 10px;
    top: -14px;
    border: 7px solid transparent;
    border-bottom: 7px solid var(--white);
  }

  .dropdown-toggle {
    width: 100%;
  }

  .dropdown-toggle:after {
    display: none;
  }

  .menu-icon {
    display: none;
  }
}

@include media-breakpoint-down(lg) {
  .header {
    .button-disableAd {
      display: none;
    }

    .userGames {
      display: none;
    }

    .dropdown {
      display: none;
    }

    .menu-icon {
      display: block;
      float: right;
      margin-right: 0px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .header {
    height: 50px;
  }
}
