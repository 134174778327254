.dice {
  position: relative;
  margin: 2% 3%;
  width: 13%;
  align-items: center;
  border-radius: 25%;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.56);
}

.dice::before {
  content: "";
  padding-top: 100%;
  display: block;
}

.dice_black {
  background: linear-gradient(to bottom, #{$gray} 0.1%, #{$codGray} 96.3%);
}

.dice_white {
  background: linear-gradient(to bottom, var(--white) -11.4%, #{$rollingStone} 130.1%);
}

.diceContent {
  position: absolute;
  top: 4%;
  left: 4%;
  bottom: 4%;
  right: 4%;
  border-radius: 45%;
  padding: 10%;
}

.diceContent_black {
  background: linear-gradient(to bottom, #{$gray} -37.7%, #{$codGray} 134.1%);
}

.diceContent_white {
  background: linear-gradient(to bottom, var(--white) -63.4%, #{$rollingStone} 208.1%);
}

.diceRow {
  height: 33.3%;
}

.diceColum {
  width: 33.3%;
  height: 100%;
  float: left;
  padding: 5%;
}

.diceFilled {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.diceFilled_black {
  background: linear-gradient(to bottom, #878C8E 0%, #E7E8E8 100%);
}

.diceFilled_white {
  background: linear-gradient(to bottom, #767675 0%, #1F1F1F 100%);
}

.clickable {
  cursor: pointer;
}
