.pick-direct {
  width: 51px;
  height: 40px;
  opacity: 0.5;
  border: 5px solid transparent;
  margin: 8px;
  border-radius: 5px;

  &.rotate {
    transform: rotate(180deg);
  } 

  &--picked {
    border-color: var(--green);
    opacity: 1;
  }
}
