.content-button {
  border: none;
  height: 40px;
  border-radius: 45px;
  padding: 0;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &__text {
    font-family: 'Roboto Slab';
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: var(--white);
    margin: 11px 15px 11px 10px;
    white-space: nowrap;
  }
}

@include media-breakpoint-down(sm) {
  .content-button {
    height: 30px;

    &__content {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-left: 3px;
      margin-top: 3px;
      margin-bottom: 3px;
      background: var(--white);
    }

    &__text {
      font-size: 10px;
      line-height: 13px;
      margin-left: 7px;
      margin-right: 12px;
      margin-top: 9px;
      margin-bottom: 8px;
      white-space: nowrap;
    }
  }
}
