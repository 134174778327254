.rolls {
  &__text {
    font-size: 1.5vw;
    opacity: 0.45;
    color: black;
    pointer-events: none;
  }
  .dice {
    -webkit-animation: spin 0.35s linear infinite;
    -moz-animation: spin 0.35s linear infinite;
    animation: spin 0.35s linear infinite;
    filter: blur(1px);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 1);
  }
}

.mobile .rolls__text {
  font-size: 100%;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
