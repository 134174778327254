body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.auth-info {
  display: none;
  position: absolute;
  color: greenyellow;
  height: 12px;
  width: 131px;
  z-index: 1;
  font-size: 0.7em;
  padding: 0px;
  opacity: 0.2;
}
